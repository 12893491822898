@import "styles/breakpoints";
@import "styles/variables";

.footer {
	background-color: rgb(32,50,62);
	padding: .5rem 0;
    margin-top: 2em;
    width: 100%;
    position: fixed;
    bottom: 0;
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 2em;
        max-width: 1366px;
    }
    &__title {
        color: $light-grey;
        font-size: .7rem;
    }
    &__copyright {
        color: $light-grey;
        font-size: .7rem;
    }
}