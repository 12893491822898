@import 'styles/variables';
@import 'styles/breakpoints';


.callToWork {
    grid-column: 2 /span 10;
    grid-row: 5;
    margin: 3em 0;
    text-align: center;
    @media (max-width: $desktop_xsm) {
        grid-column: 1/ span 8;
    }
    &__title {
        color: $dark-grey;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 1em;
    }
    &__link {
        color: $dark;
        font-weight: 700;
        font-size: 3em;
        transition: all .4s;
        position: relative;
        @media (max-width: $desktop_xsm) {
            font-size: 2em;
        }
        &:hover::before {
            right: 0;
        }
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 100%;
            bottom: -2px;
            height: 4px;
            background: $dark;
            border-radius: 5px;
            -webkit-transition-property: right;
            transition-property: right;
            -webkit-transition-duration: .3s;
            transition-duration: .3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }
}