@import 'styles/variables';
@import 'styles/breakpoints';

.ctaContainer {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    column-gap: 1em;
    grid-column: 2/span 10;
    grid-row: 3;
    margin: 4em 0 2em;
    @media (max-width: $desktop_xsm) {
        grid-template-columns: repeat(8, 1fr);
        grid-column: 1 /span 8;
        column-gap: .5em;
        margin: 2em 0 2em;
    }
    &__imgContainer {
        grid-column: 1 / span 4;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
        }
        &__img {
            display: block;
        }
    }
    &__article {
        grid-column: 6/ span 5;
        display: flex;
        flex-direction: column;
        align-self: center;
        @media (max-width: $desktop_xsm) {
            grid-column: 1/ span 8;
            margin-top: 2em;
        }
        &__title {
            font-size: 2em;
            margin-bottom: 1em;
        }
        &__subTitle {
            color: $dark-grey;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 1em;
        }
        &__lead {
            color: $dark-grey;
            font-weight: 500;
            margin-bottom: 1em;
            font-size: 1.125em;
            line-height: 1.5em;
            margin-top: 0;
        }
        &__txt {
            column-count: 2;
            font-size: 1.125em;
            line-height: 1.5em;
            @media (max-width: $desktop_xsm) {
                column-count: 1;
            }
        }
    }
    &__linkWraper {
        grid-column: 6 / span 3;
        margin-top: 2em;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 4;
        }
        &__link {
            color: $dark;
            transition: all .5s;
            font-weight: 700;
            font-size: 1.2em;
            transition: all .4s;
            position: relative;
            @media (max-width: $desktop_xsm) {
                margin-bottom: 0;
                grid-column: 1 / span 3;
            }
            &:hover::before {
                right: 0;
            }
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 0;
                right: 100%;
                bottom: -2px;
                height: 2px;
                background: $dark;
                border-radius: 5px;
                -webkit-transition-property: right;
                transition-property: right;
                -webkit-transition-duration: .3s;
                transition-duration: .3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
        }
    }
}