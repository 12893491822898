@import "styles/_variables.scss";
@import "styles/_breakpoints.scss";

.header {
	background-color: $backgroundHeader;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	padding: 2rem 2rem;
	width: 100%;
	z-index: 999;
	max-width: 1366px;
    margin: 0 auto;
	position: sticky;
	left: 0;
	right: 0;
	top: 0;
	@media (max-width: $desktop_xsm) {
		grid-template-columns: repeat(8, 1fr);
	}
	a {
		color: $dark;
		font-size: .9em;
		letter-spacing: -1px;
		text-transform: uppercase;
	}
	&__img {
		width: 3em;
	}
	&__logo {
		grid-column: 1 / span 1;
		margin: 0;
		width: 2em;
		a {
			display: block;
		}
	}
	&__title {
		align-items: center;
		display: flex;
		font-weight: 700;
		grid-column: 2 / span 2;
		transition: all .5s;
		@media (max-width: $desktop_xsm) {
			display: none;
		}
		a {
			font-size: 1.5em;
			text-transform: capitalize;
			letter-spacing: -1px;
		}
		&:hover {
			opacity: .8;
		}
	}
	&__portfolio {
		align-items: center;
		display: flex;
		justify-content: end;
		font-weight: 700;
		grid-column: 10 / span 1;
		transition: all .5s;
		@media (max-width: $desktop_xsm) {
			grid-column: 3 / span 1;
		}
		&:hover {
			transform: scale(1.1);
			opacity: .8;
		}
	}
	&__sobre {
		align-items: center;
		display: flex;
		justify-content: end;
		font-weight: 700;
		grid-column: 11 / span 1;
		transition: all .5s;
		@media (max-width: $desktop_xsm) {
			grid-column: 6 / span 1;
		}
		&:hover {
			transform: scale(1.1);
			opacity: .8;
		}
	}
	&__mail {
		align-items: center;
		display: flex;
		justify-content: end;
		font-weight: 700;
		grid-column: 12 / span 1;
		transition: all .4s;
		&:hover {
			transform: scale(1.1);
			opacity: .8;
		}
	}
	&__mailContainer {
		display: flex;
		align-items: center;
		a {
			display: flex;
		}
	}
}
.menu__container {
	display: flex;
	align-items: center;
	cursor: pointer;
	&__icon {
		margin-right: 5px;
	}
	&__title {
		font-size: 1.5em;
	}
}