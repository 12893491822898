@import 'styles/variables';
@import 'styles/breakpoints';

.home {
    display: grid;
    height: 100%;
    grid-row-gap: 0;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 2rem;
    align-content: start;
    max-width: 1366px;
    margin: 2em auto;
    @media (max-width: $desktop_xsm) {
        grid-template-columns: repeat(8, 1fr);
        margin: 2em 0 0;
    }
    &__img {
        width: 90%;
        grid-column: 8 / span 5;
        grid-row: 1;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            grid-row: 1;
            margin-bottom: 2em;
        }
        
    }
}
.container {
    grid-column: 2 / span 10;
    grid-row: 1;
    margin-bottom: 18em;
    height: auto;
    @media (max-width: $desktop_xsm) {
        grid-column: 1 / span 8;
        margin-bottom: 3em;
        grid-row: 2;
    }
    &__name {
        font-size: 1em;
        font-weight: 500;
        color: $dark-grey;
        margin-bottom: 2em;
        @media (min-width: $mobile) {  
            padding-top: 1em; 
        } 
    }
    &__title {
        font-size: 2.5em;
        font-weight: 700;
        color: $dark;
        margin-bottom: .75em;
        line-height: 2.5rem;
        @media (min-width: $mobile) {
            font-size: 2.75rem;
            transform: translate3d(0, 0, 0);
        }
        @media (max-width: $desktop_xsm) {
            margin-bottom: .5em;
            font-size: 2em;
		}
    }
    &__text {
        font-size: 1em;
        font-weight: 500;
        color: $dark-grey;
        margin-bottom: .75em;
        line-height: 1.5rem;
        @media (min-width: $mobile) {
            transform: translate3d(0, 0, 0);
            width: 50%;
        }
    }
    &__botaoWraper {
        display: flex;
        align-items: center;
        &__link {
            color: $dark;
            transition: all .5s;
            font-weight: 700;
            font-size: 1.2em;
            transition: all .4s;
            position: relative;
            @media (max-width: $desktop_xsm) {
                margin-bottom: 0;
            }
            &:first-child {
                margin-right: .5em;
            }
            &:last-child {
                margin-left: .5em;
            }
            &:hover::before {
                right: 0;
            }
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 0;
                right: 100%;
                bottom: -2px;
                height: 2px;
                background: $dark;
                border-radius: 5px;
                -webkit-transition-property: right;
                transition-property: right;
                -webkit-transition-duration: .3s;
                transition-duration: .3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
        }
    }
}