@import 'styles/variables';
@import 'styles/breakpoints';

.formContainer {
    background-color: $background;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 2rem;
    max-width: 1366px;
    margin: 2em auto 3em;
    &__side {
        grid-column: 1 / span 4;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 6;
            margin-bottom: 2em;
        }
        &__title {
            color: $dark;
            font-weight: 700;
            font-size: 2em;
        }
        &__subTitle {
            color: $dark-grey;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 1em;
        }
    }
    @media (max-width: $desktop_xsm) {
        grid-template-columns: repeat(8, 1fr);
    }
    &__wrapper {
        grid-column: 6 / span 6;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
        }
    }
    &__header {
        grid-column: 6 / span 6;
        margin-bottom: 2em;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
        }
        &__subtitle {
            font-size: 1.2rem;
            text-transform: initial;
            font-weight: bold;
            color: $dark-grey;
            margin-bottom: 0.5rem;
        }
        &__title {
            font-size: 1.8rem;
            margin-bottom: 0.5em;
        }
    }
}
.form__field {
    padding: 1em 0 0;
    margin-bottom: 1em;
    grid-row: 1;
    // border-bottom: 1px solid $dark;
    input {
        background-color: $background;
        border-bottom: 1px solid $dark;
        border-top: none;
        border-left: none;
        border-right: none;
        margin-top: .5em;
        outline: none;
        width: 100%;
    }
    textarea {
        background-color: $background;
        border-bottom: 1px solid $dark;
        border-top: none;
        border-left: none;
        border-right: none;
        margin-top: .5em;
        outline: none;
        width: 100%;
    }
}
.field__button {
    display: flex;
    justify-content: flex-end;
}
.button {
    padding: 1em .5em;
    color: $light-grey;
    background-color: $dark;
}

.form__checkbox {
    padding: 1em 0 0;
    border-bottom: 0;
    margin-bottom: 1em;
    &__wrapper {
        margin-top: 1em;
        label {
            padding-left: .5em;
        }
    }
}
.error__message {
    color: red;
}
