@import 'styles/variables';
@import 'styles/breakpoints';

.recentes {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1em;
    grid-column: 2/span 10;
    grid-row: 4;
    margin: 4em 0 2em;
    @media (max-width: $desktop_xsm) {
        grid-template-columns: repeat(8, 1fr);
        grid-column: 1 /span 8;
        column-gap: .5em;
        grid-row: 4;
        margin: 2em 0 2em;
    }
    &__titulo {
        grid-column: 1/span 2;
        font-size: 1.5em;
        font-weight: bold;
        letter-spacing: -1px;
        grid-row: 2;
        @media (max-width: $desktop_xsm) {
            grid-column: 1/ span 4;
        }
    }
    &__subTitle {
        grid-column: 1/span 2;
        color: $dark-grey;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 1em;
        grid-row: 1;
        @media (max-width: $desktop_xsm) {
            grid-column: 1/ span 4;
        }
    }
}