$padding-h-mobile: 1.25em;
$padding-h-sm: 9.37em;
$padding-h-md: 12.5em;
$padding-h: 15.62em;

$dark: rgb(32,50,62);
$dark-grey: rgb(126,132,137);
$light-grey: #e4e4e4;
$background: #FCFCFC;
$backgroundHeader: rgba(251, 251, 251,0.95);
$wite: #ffffff;

$blue: #0D0080;
$orange: #FF6A00;
$lightBlue: #008ccf;

$shadow: rgba(32,50,62,.3);

$label: rgba(black,.7);
$border: rgba(black,.3);

$active: black;
$focus: black;
$error: red;