@import "./styles/normalize.css";


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

:root {
	--dark: rgb(32,50,62);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCFCFC;
  color: var(--dark);
  margin: 0;
}

#root > main.container {
  margin: 0 auto;
  display: grid;
	grid-template-areas: 
		"header"
		"main"
		"footer";
	grid-template-columns: 100%;
	grid-template-rows: 6rem auto auto;
	grid-row-gap: 0;
}

.header {
	grid-area: header;
}
.main {
	grid-area: main;
}

.footer {
	grid-area: footer;
}

/* menu lateral header active  */
.active {
	border-bottom: 2px solid;
}
/* input error do formulário */
.input-error {
	border-bottom: 1px solid red !important; 
}
.checkbox-error {
	border: 1px solid red !important;
}
