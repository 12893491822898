@import 'styles/variables';
@import 'styles/breakpoints';

.sobre {
    background-color: $background;
    display: grid;
    height: 100%;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 2rem;
    max-width: 1366px;
    margin: 2em auto 3em;
    grid-row-gap: 1em;
    @media (max-width: $desktop_xsm) {
        grid-template-columns: repeat(8, 1fr);
    }
    p {
        font-size: 1.3rem;
        line-height: 1.8rem;
    }
    &__subtitle {
        font-size: 1.2rem;
        font-weight: 500;
        color: $dark-grey;
        grid-column: 1 / span 12;
        grid-row: 1;
        text-align: center;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            text-align: left;
        }
    }
    &__title {
        font-size: 2.5rem;
        grid-column: 2 / span 10;
        grid-row: 2;
        text-align: center;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            text-align: left;
        }
    }
    &__mainText { 
        font-weight: bolder;
        color: $dark-grey;
        grid-column: 3 / span 8;
        grid-row: 3;
        text-align: center;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            text-align: left;
        }
    }
    &__img {
        width: 100%;
        margin-bottom: 4em;
        grid-column: 1 / span 12;
        grid-row: 4;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            margin-bottom: 1em;
        }
    }
    &__tools {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        padding: 0 0 4em;
        border-bottom: 1px solid $light-grey;
        grid-column: 2 / span 10;
        grid-row: 5;
        @media (max-width: $desktop_xsm) {
            padding: 0 0 2em;
            margin: 0 0 2em;
            grid-column: 1 / span 8;
        }
        &__title {
            color: $dark-grey;
            font-size: 2em;
            font-weight: 300;
            text-transform: uppercase;
            grid-column: 1 / span 9;
            margin-bottom: 1em;
            text-align: center;
            @media (max-width: $desktop_xsm) {
                text-align: left;
            }
        }
        &__titleDesign {
            color: $dark-grey;
            font-size: .875rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            padding-top: 5px;
            padding-bottom: 5px;
            grid-column: 1 / span 3;
            @media (max-width: $desktop_xsm) {
                grid-column: 1 / span 9;
            }
        }
        &__titleTools {
            color: $dark-grey;
            font-size: .875rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            padding-top: 5px;
            padding-bottom: 5px;
            grid-column: 6 / span 3;
            grid-row: 2;
            @media (max-width: $desktop_xsm) {
                grid-column: 1 / span 9;
                grid-row: 4;
            }
        }
        &__namesDesign {
            color: $dark;
            font-size: 1.375rem;
            line-height: 1.875rem;
            padding-top: 2px;
            padding-bottom: 3px;
            grid-column: 1 / span 4;
            @media (max-width: $desktop_xsm) {
                grid-column: 1 / span 9;
                padding-bottom: 2em;
            }
        }
        &__namesTools {
            color: $dark;
            font-size: 1.375rem;
            line-height: 1.875rem;
            padding-top: 2px;
            padding-bottom: 3px;
            grid-column: 6 / span 4;
            @media (max-width: $desktop_xsm) {
                grid-column: 1 / span 9;
            }
        }
    }
    &__titleExpirience {
        color: $dark;
        font-size: 2.125rem;
        line-height: 2.5rem;
        grid-column: 1 / span 12;
        grid-row: 6;
        text-align: center;
        margin-top: 2em;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            margin-top: 0;
            text-align: left;
        }
    }
    &__textExpirience {
        grid-column: 2 / span 10;
        grid-row: 7;
        text-align: center;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            text-align: left;
        }
        &__proactive {
            grid-column: 2 / span 10;
            grid-row: 8;
            margin-bottom: 2em;
            text-align: center;
            @media (max-width: $desktop_xsm) {
                grid-column: 1 / span 8;
                text-align: left;
            }
        }
    }
    &__titleVision {
        color: $dark;
        font-size: 2.125rem;
        line-height: 2.5rem;
        grid-column: 2 / span 10;
        grid-row: 9;
        text-align: center;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
            text-align: left;
        }
    }
    &__textVision {
        grid-column: 2 / span 10;
        grid-row: 10;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
        }
    }
    &__link {
        border-bottom: 1px solid $lightBlue;
        color: $lightBlue;
        transition: all .3s;
        &:hover {
            opacity: .6;
           }
    }
    &__mdSociais {
        margin-bottom: 3em;
        grid-column: 6 / span 2;
        align-self: center;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 5;
        }
        &__list {
            display: flex;
            justify-content: space-between;
            &__item {
                list-style: none;
            }
        }
    }
}
