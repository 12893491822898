@import 'styles/variables';
@import 'styles/breakpoints';

.projeto {
    background-color: $background;
    display: grid;
    height: 100%;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 2em 4em 2em;
    max-width: 1366px;
    margin: 2em auto;
    @media (max-width: $desktop_xsm) {
        grid-template-columns: repeat(8, 1fr);
    }
    &__imgContainer {
        grid-column: 4 / span 7;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
        }
        &__img {
            object-fit: cover;
        }
    }
    &__title {
        grid-column: 4 / span 7;
        grid-row: 2;
        font-size: 2em;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
        }
    }
    &__textContainer {
        grid-column: 4 / span 7;
        grid-row: 3;
        @media (max-width: $desktop_xsm) {
            grid-column: 1 / span 8;
        }
    }
}