@import 'styles/variables';
@import 'styles/breakpoints';

.notFound {
    display: grid;
    height: 100%;
    grid-row-gap: 0;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 2rem;
    align-content: start;
    max-width: 1366px;
    margin: 2em auto;
    @media (max-width: $desktop_xsm) {
        grid-template-columns: repeat(8, 1fr);
        margin: 2em 0 0;
    }
    &__text {
        grid-column: 6 / span 2;
        grid-row: 1;
        font-size: 3em;
        font-weight: 700;
        text-align: center;
    }
    &__title {
        grid-column: 6 / span 2;
        grid-row: 2;
        font-size: 2em;
        font-weight: 300;
        text-align: center;
    }
    &__img {
        grid-column: 5 / span 4;
        grid-row: 3;
    }
}