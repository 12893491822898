@import 'styles/variables';
@import 'styles/breakpoints';

.card {
    width: calc(50% - 1em);
    overflow: hidden;
    &:hover {
        background-color: $dark;
    }
    @media (max-width: $desktop_xsm) {
        width: 100%;
    }
    &__link {
        color: $dark;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        &:hover img {
            transform: scale(1.1);
            mix-blend-mode: overlay;
        }
        &:hover h2 {
            animation-duration: .5s;
            animation-name: animeTitle;
        }
        &:hover h3 {
            animation-duration: .5s;
            animation-delay: .2s;
            animation-name: animeTitle;
        }
        &:hover p {
            opacity: 1;
            animation-duration: .5s;
            animation-name: animeTitle;
        }
    }
    &__imgContainer {
        overflow: hidden;
        grid-column: 1 / sapan 4;
        grid-row: 1;
    }
    &__imgContainer__img {
        width: 100%;
        object-fit: cover;
        transition: transform .5s ease-in-out,-webkit-transform .5s ease-in-out;
    }
    
    &__container {
        padding: 2em;
        transform: translate3d(0, 0, 0);
        grid-row: 1;
        grid-column: 1 / span 3;
    }
    &__title {
        color: $wite;
	    font-weight: bolder;
        font-size: 2em;
        margin-bottom: .5em;
        
        @keyframes animeTitle {
            0% {
              opacity: 0;
              transform-origin: 50% 0%;
              transform: scale(0.2) translate(0%, -200%);
            }
            100% {
              opacity: 1;
              transform-origin: 50% 0%;
              transform: scale(1) translate(0%, 0%);
            }
          }
    }
    
    &__tag {
        margin-bottom: 1em;
        font-size: 1.5em;
        color: $wite;
        @keyframes animeTitle {
            0% {
              opacity: 0;
              transform-origin: 50% 0%;
              transform: scale(0.2) translate(0%, -200%);
            }
            100% {
              opacity: 1;
              transform-origin: 50% 0%;
              transform: scale(1) translate(0%, 0%);
            }
          }
    }
    &__descricao {
        color: $dark-grey;
        transform: translate3d(0, 0, 0);
        opacity: 0;
        &__icon {
            margin-left: .5em
        }
        @keyframes animeTitle {
            0% {
              opacity: 0;
              transform-origin: 50% 0%;
              transform: scale(0.2) translate(0%, -200%);
            }
            100% {
              opacity: 1;
              transform-origin: 50% 0%;
              transform: scale(1) translate(0%, 0%);
            }
        }
    }

}